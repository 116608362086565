const search_toggle = document.getElementById("mobile__search__toggle")
const search_form = document.getElementById("mobile__search__form");

function toggleForm() {
    search_form.parentElement.classList.toggle("mobile-search-open");
    search_toggle.querySelectorAll(".icon").forEach(element => {
        element.classList.toggle("icon__hidden");
    });
}

search_toggle.addEventListener("click", toggleForm);
console.log("search script loaded");
